import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, SimpleChanges, OnChanges, EventEmitter, Output, ElementRef, Renderer2, HostListener, } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PostHelpers } from 'src/app/_helpers';
import { OptionDropdown, Post, User } from 'src/app/_models';
import { DiscussionAPIService } from 'src/app/_api-services'
import { AuthenticationService, NavigationService } from 'src/app/_services';
import { PostEditorComponent } from '../post-editor/post-editor.component';
import { AlertPopupComponent } from 'src/app/shared/common/components/alert-popup/alert-popup.component';


@Component({
  selector: 'post-card',
  templateUrl: './post-card.component.html',
  styleUrl: './post-card.component.scss'
})
export class PostCardComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild(PostEditorComponent) postEditor!: PostEditorComponent
    @ViewChild('alertPopup') alertPopup!: AlertPopupComponent
    @ViewChild('discussionText', { static: false }) discussionText!: ElementRef<HTMLDivElement>

    @Input() post!: Post
    @Input() noBorder: boolean = false
    @Input() allowComments: boolean = true
    @Input() hideToolbar: boolean = false
    @Input() entityId: string | undefined;
    @Input() entity: string | undefined;
    @Input() isComment: boolean = false
    @Input() entityOwner: User | null = null
    
    @Output() timeChange: EventEmitter<string> = new EventEmitter<string>()
    
    private subscriptions: Subscription = new Subscription()
    
    loading: boolean = false
    liked: boolean = false
    coppiedMessageVisable: boolean = false
    shareMessage: string = ""
    comments: Post[] = []
    processedTxt!: SafeHtml
    optionsdropDown: boolean = false
    deleted: boolean = false
    dropdownOptions: OptionDropdown[] = []
    userId: string = ""
    postEdit: boolean = false
    postTextElement!: HTMLElement
    formData!: FormData
    currentDateTime: string = ''
    showOptions: boolean = false
    editPost: boolean = false

    showFullText = false
    isTruncated = false

    constructor(
        private discussionAPIService: DiscussionAPIService,
        private router: Router,
        private helper: PostHelpers,
        private cdr: ChangeDetectorRef,
        private authService: AuthenticationService,
        private navigationService: NavigationService,
        private renderer: Renderer2,

        private sanitizer: DomSanitizer
        
    ) {
        this.userId = this.authService.currentUserValue.user.id
    }
    
    ngOnInit(): void {
        this.processText()
        this.setDropdownOptions()
        this.updateTime()
    }
    
    ngOnChanges(changes: SimpleChanges): void { 
        if(changes['post']) {
            this.processText()
        }

        if (changes['post'] && this.post) {
            setTimeout(() => {
                this.updateUserCardPositions()
            }, 1000)
        }
    }

    ngAfterViewInit() {
        const element = this.discussionText.nativeElement
        
        if(this.router.url.includes('details')) {
            this.isTruncated = false
            this.showFullText = true
            this.cdr.detectChanges()
            return 
        }
        
        this.isTruncated = element.scrollHeight > element.clientHeight
        this.cdr.detectChanges()
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    updateTime(): void {
        this.currentDateTime = new Date().toISOString()
        this.cdr.markForCheck()
        setTimeout(() => this.updateTime(), 60000)
        this.timeChange.emit(this.currentDateTime)
    }

    handleComment() {
        this.router.navigate([`/post/${this.post.id}/details`])
    }
    
    processText(): void {
        this.processedTxt = this.helper.processPost(this.post)
        this.cdr.detectChanges()
    }

    toggleText(event: MouseEvent) {
        event.stopPropagation()
        this.showFullText = !this.showFullText
    }
    
    addComment(text: string): void {
        const formData = new FormData()
        formData.append('text', text)
        formData.append('entity_id', this.post.id)
        formData.append('rooms', JSON.stringify([this.post.id]))
        formData.append('entity', 'post')
        
        
        this.subscriptions.add(
            this.discussionAPIService.createPost(formData).subscribe( res => {
                if(!res) {
                    return
                }
                
                this.comments.push(res)
            })    
        )
    }

    toggleOptionsDropdown(): void{
        this.optionsdropDown = !this.optionsdropDown
    }
    
    setDropdownOptions(): void{
        const userOwnsPost = this.post.posted_by.id === this.userId
        
        const userIsEntityOwner = this.entityOwner && this.entityOwner.id == this.userId ? true : false
        
        if(!userIsEntityOwner && (!userOwnsPost || this.post.comments?.length > 0)) {
            return
        }
        
        this.showOptions = true
        this.cdr.detectChanges()
        
        if(userOwnsPost) {
            this.dropdownOptions.push({ label: 'Edit', action: () => this.onEditClick(), keep_open: false })
        }
        
        this.dropdownOptions.push({ label: 'Delete', action: () => this.showDeleteAlert(), keep_open: false })
    }

    showDeleteAlert() {
        this.alertPopup.title = 'Confirm Deletion'
        this.alertPopup.message = 'Are you sure you want to delete this post?'
        this.alertPopup.buttons = [
            {
                name: 'Cancel',
                action: () => this.alertPopup.hide()
            },
            {
                name: 'Delete',
                action: () => {
                    this.deletePost(this.post.id)
                    this.alertPopup.hide()
                }
            }
        ]
        this.alertPopup.show()
    }
    
    onEditClick(): void {
        this.editPost = true
    }
    
    updatePost(event: Event): void {
        event.stopPropagation()
        this.loading = true
        this.postEditor.post()
        this.editPost = false
    }
    
    cancelEdit(event: Event): void {
        event.stopPropagation()
        this.editPost = false
    }

    onSubmit(text: string): void{
        if(!this.entityId || !this.entity || text.trim().length < 1){
            this.loading = false
            return         
        }
        
        this.postEdit = false
        
        this.formData = new FormData()
        this.formData.append('text', text.trim())
        this.formData.append('entity_id', this.entityId)
        this.formData.append('rooms', JSON.stringify([this.entityId]))
        this.formData.append('entity', this.entity)
        this.formData.append('links_to', JSON.stringify(this.post))
        this.formData.append('ID', this.post.id)
        
        this.subscriptions.add(
            this.discussionAPIService.updatePost(this.formData).subscribe(done => {
                this.loading = false
            })
        )
    }
    
    handleInputClick(event: Event){
        event?.stopPropagation()
    }

    deletePost(postId: string): void {
        this.subscriptions.add(
            this.discussionAPIService.deletePost(postId).subscribe({
                next: () => {
                    this.deleted = true
                    
                    if(!this.router.url.includes('details')) {
                        return 
                    }
                    
                    const lastPage = this.navigationService.getPreviousPage()
                    let page = lastPage
            
                    if (lastPage == "") {
                        page = "/" + this.entityId + "/posts"
                    }
                    
                    this.router.navigateByUrl(page)
                }
            })
        )
    }

    updateUserCardPositions(): void {
        const mentions = this.discussionText.nativeElement.querySelectorAll('.mention')
        
        mentions.forEach((span, i) => {
            const anchor = span.querySelector('a')!
            const parentRect = span.getBoundingClientRect()
            const topPos = parentRect.top - 70 //70 is the height of the mention element (can't do dynamically because of display none)
            this.renderer.setStyle(anchor, 'top', `${topPos}px`)
            this.renderer.setStyle(anchor, 'left', `${parentRect.left}px`)
        })
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: Event) {
        this.updateUserCardPositions()
    }
    
    @HostListener('window:resize', ['$event'])
    onWindowsResize(event: Event) {
        this.updateUserCardPositions()
    }

}
