<div class="mention">
    <a class="user-card">
        <div class="img-container">
            <img [src]="avatar" [alt]="user.first_name + ' ' + user.last_name">
        </div>
        <div class="flex flex-center user-name">
            <p>{{ user.first_name }} {{ user.last_name }}</p>
        </div>
    </a>
    <span>{{ '@' }}{{ user.first_name }} {{ user.last_name }}</span>
</div>