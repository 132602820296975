import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PostCardComponent } from './components/post-card/post-card.component';
import { CommentInputComponent } from './components/comment-input/comment-input.component';
import { PostActionBarComponent } from './components/post-action-bar/post-action-bar.component';
import { SharedCommonModule } from '../common/shared-common.module';
import { CommentCardComponent } from './components/comment-card/comment-card.component';
import { CommentActionBarComponent } from './components/comment-action-bar/comment-action-bar.component';
import { PostFormComponent } from './components/post-form/post-form.component';
import { PostsComponent } from './components/posts/posts.component';
import { MentionDirective } from 'src/app/_directives/mention.directive';
import { MentionComponent } from './components/mention/mention.component';
import { PostEditorComponent } from './components/post-editor/post-editor.component';
import { PostNotFoundComponent } from '../../main/discussion/components/post-not-found/post-not-found.component';
import { DirectivesModule } from '../directives/directives.module';



@NgModule({
	declarations: [
		PostCardComponent,
		CommentInputComponent,
		CommentCardComponent,
		PostActionBarComponent,
		CommentActionBarComponent,
		PostFormComponent,
		PostsComponent,
        MentionDirective,
        MentionComponent,
        PostEditorComponent,
		PostNotFoundComponent,
	],
	imports: [
		CommonModule,
		SharedCommonModule,
        RouterModule,
        DirectivesModule,
	],
	exports:[
		PostCardComponent,
		PostFormComponent,
		CommentInputComponent,
		CommentCardComponent,
		PostActionBarComponent,
		PostsComponent,
		PostNotFoundComponent,
        DirectivesModule,
	],
    providers: [
        DatePipe,
    ]
})
export class DiscussionsModule { }
