import { Component, OnInit, ViewChild, ElementRef, TemplateRef, Input, OnDestroy, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { Business, Hive, HubhiveEvent, Post, User } from 'src/app/_models';
import { Subscription} from 'rxjs';
import { DiscussionAPIService } from 'src/app/_api-services';
import { ScrollPositionService } from 'src/app/_services';
import { ActivatedRoute,  Router } from '@angular/router';

@Component({
    selector: 'posts',
    templateUrl: './posts.component.html',
    styleUrl: './posts.component.scss'
})
export class PostsComponent implements OnInit, OnChanges, OnDestroy {
    @Input('entityType') entityType: string = ''
    @Input('modalTitle') modalTitle: string = ''
    @Input() entityObject!: Hive | Business | HubhiveEvent
    @Input() entityOwner!: User
    
    @ViewChild('searchInput') input!: ElementRef<HTMLInputElement>
    @ViewChild('modalContent') modalContent!: TemplateRef<any>

    private subscriptions: Subscription = new Subscription()
    
    posts: Post[] = []
    postText: string = ''
    isListening: boolean = false
    showForm: boolean = false
    goToSavedScrollPosition: boolean = true
    page: number = 1
    perPage: number = 20
    loading: boolean = true
    theEnd: boolean = false
    initiating: boolean = false

    constructor(
        private discussionAPIService: DiscussionAPIService,
        private scrollPositionService: ScrollPositionService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
    }
    
    ngOnInit(): void {
        if(!this.entityObject) {
            throw Error('Entity Object must be set!')
        }
        
        this.subscriptions.add(
            this.route.parent?.params.subscribe( params => {
                this.reset()
            })
        )
        
        this.doInitiation()
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if(changes['entityObject'] && changes['entityObject'].previousValue) {
            this.doInitiation()
        }
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
        this.discussionAPIService.leaveRoom(this.entityObject.id)
    }

    doInitiation(): void {
        if(!this.entityObject.id || this.isListening) {
            return
        }
        
        this.getRecords()
        
        this.isListening = true
        this.subscriptions.add(
            this.discussionAPIService.listenForPost(this.entityObject.id).subscribe( newPost => {
                const index = this.posts.findIndex(post => post.id === newPost.id)
                if (index === -1) {
                    this.posts.unshift(newPost)
                } else {
                    this.posts[index] = newPost
                }
                
                this.initiating = false
            })
        )

    }
    
    getRecords(): void {
        this.loading = true
        switch (this.entityType) {
            case "hive":
                this.doHiveInitiation()
                break
            case "event":
                this.doEventInitiation()
                break
            case "business":
                this.doBusinessInitiation()
                break
            default:
                throw Error("this entity post type is not setup")
        }
    }

    doHiveInitiation(): void {
        this.subscriptions.add(
            this.discussionAPIService.getHivePosts(this.entityObject.id, this.page, this.perPage).subscribe( {
                next: (posts) => {
                    this.setPosts(posts)
                },
                complete: () => {
                    this.scrollToSavedPosition()
                    this.loading = false
                }
            })
        ) 
    }

    doEventInitiation(): void {
        this.subscriptions.add(
            this.discussionAPIService.getEventPosts(this.entityObject.id, this.page, this.perPage).subscribe( {
                next: (posts) => {
                    this.setPosts(posts)
                },
                complete: () => {
                    this.scrollToSavedPosition()
                    this.loading = false
                }
            })
        ) 
    }

    doBusinessInitiation(): void {
        this.subscriptions.add(
            this.discussionAPIService.getBusinessPosts(this.entityObject.id, this.page, this.perPage).subscribe( {
                next: (posts) => {
                    this.setPosts(posts)
                },
                complete: () => {
                    this.scrollToSavedPosition()
                    this.loading = false
                }
            })
        ) 
    }
    
    setPosts(posts: Post[]): void {
        if(!posts) {
            this.theEnd = true
            return
        }
        
        if(posts.length < this.perPage) {
            this.theEnd = true
        }
        
        this.posts.push(...posts)
    }

    handleMakePostClick() {
        this.showForm = true
    }

    onCloseForm() {
        this.showForm = false
    }
    
    scrollToSavedPosition(): void {
        if(!this.goToSavedScrollPosition) {
            return
        }
        
        let y = this.scrollPositionService.getScrollPosition(this.router.url) || 0
        setTimeout(() => window.scrollTo(0, y), 200)
    }
    
    @HostListener('window:scroll', [])
    onWindowScroll(): void {
        const bottomReached = (window.innerHeight + Math.round(window.scrollY)) >= document.body.scrollHeight
        
        if (bottomReached && !this.loading && !this.theEnd) {
            this.loadMore()
            this.goToSavedScrollPosition = false
            this.loading = true
        }
    }
    
    loadMore(): void {
        this.page++
        this.getRecords()
    }
    
    reset(): void {
        this.posts = []
        this.isListening = false
        this.page = 1
        this.discussionAPIService.leaveRoom(this.entityObject.id)
    }
}
