import { Component } from '@angular/core';
import { DefaultImage } from 'src/app/_enums';
import { User } from 'src/app/_models';


@Component({
    selector: 'mention',
    templateUrl: './mention.component.html',
    styleUrl: './mention.component.scss'
})
export class MentionComponent {
    user!: User;
    avatar: string = DefaultImage.UserAvatar;
    showUserCard: boolean = false;
}
