import { Component, OnDestroy, ViewChild, Input, ViewChildren, QueryList, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Business, Hive, HubhiveEvent, User } from 'src/app/_models';
import { DiscussionAPIService } from 'src/app/_api-services'
import { AuthenticationService, PostEditorService } from 'src/app/_services';
import { CommonValidators } from 'src/app/_validators/common.validators';
import { FileDropzoneComponent } from 'src/app/shared/common/components/file-dropzone/file-dropzone.component';
import { PostEditorComponent } from '../post-editor/post-editor.component';

@Component({
    selector: 'post-form',
    templateUrl: './post-form.component.html',
    styleUrl: './post-form.component.scss'
})
export class PostFormComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription()
    @ViewChildren('fileDropZoneComponents') fileDropZoneComponents!: QueryList<FileDropzoneComponent>
    @ViewChild(PostEditorComponent) textInput!: PostEditorComponent
    
    @Output() closeForm = new EventEmitter<void>()
    @Input() entity!: string
    @Input() title: string = "Create a Post"
    @Input() entityObject!: Hive | Business | HubhiveEvent

    postForm!: FormGroup
    postImages: File[] = []
    dropZoneAdded: boolean = false
    showMessage = false
    showLoader = false
    currentUser: User
    postText: string = ''
    errorText: string | null = null

    constructor(
        private authService: AuthenticationService,
        private fb: FormBuilder,
        private discussionAPIService: DiscussionAPIService,
        private cdr: ChangeDetectorRef,
        private postEditorService: PostEditorService,
    ) {
        this.currentUser = this.authService.currentUserValue.user
        this.formSetup()
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    formSetup(): void {
        this.postForm = this.fb.group({
            images: this.fb.array([
                this.createImageFormGroup(0),

            ]),
        })
    }
    
    createImageFormGroup(index: number): FormGroup {
        return this.fb.group({
            image: ['', [
                CommonValidators.isImage(() => this.postImages[index]),
                CommonValidators.maxFileSize(5, () => this.postImages[index])
            ]]
        });
    }

    get images(): FormArray {
        return this.postForm.get('images') as FormArray
    }

    getGroup(index: number): FormGroup {
        return this.images.at(index) as FormGroup
    }
    
    onPaste(event: ClipboardEvent): void {
        const clipboardData = event.clipboardData;
        if (!clipboardData) {
            return
        }
        
        const items = clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') === -1) {
                continue
            }
            
            const file = items[i].getAsFile()
            
            if(!file) {
                continue
            }
            
            if(!this.dropZoneAdded) {
                this.showFileDropZone(false)
            }
            
            this.fileDropZoneComponents.last.evaluateFile(file)
        }
    }

    addImage(index: number): void {
        this.images.push(this.createImageFormGroup(index))
    }

    removeImage(index: number): void {
        this.images.removeAt(index)

        if (this.postImages[index]) {
            this.postImages.splice(index, 1) //Remove image from array    
            this.addNextDropzone(1)
        }
    }

    appendImage(image: File | null, index: number): void {
        if (!image) {
            delete this.postImages[index]
        } else {
            this.postImages[index] = image
        }
        this.addNextDropzone(index + 1)
    }

    addNextDropzone(index: number): void {
        if (index < 0 || this.postImages.length < this.images.length || index >= 4) {
            return
        }
        this.addImage(index)
    }

    onFileChange(event: any, index: number): void {
        const file = event.target.files[0];
        if (file) {
            this.appendImage(file, index);
        }
    }

    getInvalidInputs(): string[] {
        const invalidInputs: string[] = [];
        Object.keys(this.postForm.controls).forEach(key => {
            const control = this.postForm.get(key);
            if (control && control.invalid) {
                invalidInputs.push(key);
            }
        });
        return invalidInputs;
    }

    toggleFileDropZone(): void {
        if (this.dropZoneAdded) {
            this.dropZoneAdded = false;
            this.postForm.removeControl('images')
            return
        }
        
        this.postForm.addControl('images', this.fb.array([
            this.createImageFormGroup(0),
        ]))
        
        this.dropZoneAdded = true;
    }

    showFileDropZone(clickDropzone: boolean = true): void {
        this.dropZoneAdded = true
        

        this.postForm.addControl('images',this.fb.array([
            this.createImageFormGroup(0),
        ]))

        this.cdr.detectChanges()
        const lastFileDropzone = this.fileDropZoneComponents.last
        
        if(lastFileDropzone && clickDropzone) {
            lastFileDropzone.onClick()
        }
    }
    
    setPostText(txt: string): void {
        this.postText = txt
        this.createPost()
    }
    
    submit(): void {
        this.textInput.post()
    }

    createPost(): void {
        if (!this.postForm.valid || !this.postText) {
            this.postForm.markAllAsTouched()
            return
        }
        
        this.showLoader = true

        const formData = new FormData()

        if(!this.entityObject.id){
            this.entityObject.id = ""
        }

        formData.append('text', this.postText)
        formData.append('entity_id', this.entityObject.id)
        formData.append('entity', this.entity)
        formData.append('rooms', JSON.stringify([this.entityObject.id]))
        formData.append('links_to', JSON.stringify(this.entityObject))

        if (this.postImages && this.postImages.length) {
            for (let i = 0; i < this.postImages.length; i++) {
                formData.append(`image[${i}]`, this.postImages[i]);
            }
        }
        
        this.subscriptions.add(
            this.discussionAPIService.createPost(formData).subscribe({
                next: post => {
                    this.postEditorService.clear.next()
                    this.showLoader = false
                    this.close()    
                },
                error: err => {
                    this.showLoader = false
                    this.errorText = 'Something went wrong when trying to create your post. Please wait and try again.'
                }
            })
        )
    }

    addMorePhotosButton(): void {
        const lastFileDropzone = this.fileDropZoneComponents.last
        const lastControl = this.images.at(this.images.length - 1)
        const lastControlHasImage = lastControl && lastControl.value.image !== ''
        
        if (!lastControlHasImage) {
            lastFileDropzone.onClick()
            return
        }
        
        this.showMessage = true
        setTimeout(() => {
            this.showMessage = false
        }, 3000)
    }
      

    autoResize(event: Event): void {
        const textarea = event.target as HTMLTextAreaElement
        textarea.style.height = 'auto'
        textarea.style.height = textarea.scrollHeight + 'px' 
    }

    close() {
        this.closeForm.emit()
    }

}
