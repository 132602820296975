<div class="discussion-interactions" *ngIf="post">
    <div class="interaction-group" (click)="handleCommentClick()">
        <img src="/assets/icon-speech-bubble.svg" alt="comment icon">
        <span *ngIf="post.comments">{{post.comments.length}}</span>
        <span *ngIf="!post.comments && post.comment_count != null">{{post.comment_count}}</span>
    </div>
    <div class="interaction-group action-identities" (click)="handleLike()">
        <img *ngIf="!liked" src="/assets/icon-heart.svg" alt="heart icon">
        <img *ngIf="liked" src="/assets/icon-heart-red.svg" alt="heart icon">
        <span>{{post.likes}}</span>
        <div class="all-identities" *ngIf="post.liked_by && post.liked_by.length">
            <p *ngFor="let u of post.liked_by">{{ u.first_name }} {{ u.last_name }}</p>
        </div>
    </div>
    <div class="interaction-group" (click)="handleShare()">
        <img src="/assets/icon-share.svg" alt="share icon">
    </div>
    <div>
        <div *ngIf="clipboardHelper.copiedMessageVisable" class="message">
            {{clipboardHelper.shareMessage}}
        </div>
    </div>
</div>