<div class="container comment-input">
    <div class="initiator" (click)="handleMakePostClick()">Write Something</div>
</div>
<div *ngIf="posts && posts.length">
    <div *ngFor="let post of posts">
        <post-card [post]="post" [entity]="entityType" [entityId]="entityObject.id" [entityOwner]="entityOwner"></post-card>
    </div>
</div>

<loading-spinner *ngIf="loading"></loading-spinner>

<post-form *ngIf="showForm" [entity]="entityType" [title]="modalTitle" [entityObject]="entityObject" (closeForm)="onCloseForm()"></post-form>