import { Component, Output, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Post } from 'src/app/_models';
import { DiscussionAPIService } from 'src/app/_api-services'
import { AuthenticationService, PostEditorService } from 'src/app/_services';
import { ClipboardHelper } from 'src/app/_helpers/clipboard.helper';


@Component({
	selector: 'post-action-bar',
	templateUrl: './post-action-bar.component.html',
	styleUrl: './post-action-bar.component.scss'
})
export class PostActionBarComponent implements OnInit, OnDestroy {

	@Output() handleComment: EventEmitter<void> = new EventEmitter<void>()
	@Input() post!: Post


	userId!: string
	loading: boolean = false
	liked: boolean = false
	coppiedMessageVisable: boolean = false
	shareMessage: string = ""
    likedByText: string | null = null
    extraLikeByCount: number = 0

	private subscriptions: Subscription = new Subscription()

	constructor(
        private authService: AuthenticationService,
        private discussionAPIService: DiscussionAPIService,
        public clipboardHelper: ClipboardHelper,
		private postEditorService: PostEditorService,
	) {
	}

	handleCommentClick(): void{
        this.postEditorService.focus.next()
		this.handleComment.emit()
	}

	ngOnInit(): void {
		let localStorage = this.authService.currentUserValue
		this.userId = localStorage.user.id
        
		this.userLiked()
        this.setUsersThatReactedText()
	}
    
    ngOnDestroy(): void {
		this.subscriptions.unsubscribe()
	}

	handleLike() {
		if(this.liked){
			this.subscriptions.add(this.discussionAPIService.unlikePost(this.post.id).subscribe())
			this.post.likes--
		} else{
			this.subscriptions.add(this.discussionAPIService.likePost(this.post.id, 'post', this.post).subscribe())
			this.post.likes++
		}
        
		this.liked = !this.liked
	}

	userLiked() {
        if(!this.post || !this.post.liked_by) {
            return
        }
        
		let containsUserId = this.post.liked_by.some((user: any)=>this.userId == user.id)
		this.liked = this.post.liked_by ? containsUserId : false
	}
    
    setUsersThatReactedText(): void {
        if(!this.post || !this.post.liked_by || !this.post.liked_by.length) {
            return
        }
        
        this.likedByText = this.post.liked_by[0].first_name + " " + this.post.liked_by[0].last_name
        
        if(this.post.liked_by.length > 1) {
            this.extraLikeByCount = this.post.liked_by.length - 1
            this.likedByText += ` and ${this.extraLikeByCount}`
        }
    }

	handleShare() {
		this.clipboardHelper.handleShare(`/post/${this.post.id}/details`) 
	}
}
