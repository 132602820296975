<div #editor class="editor" tabindex="1" (touchstart)="focusing()" (blur)="blur()"></div>
<div *ngIf="suggestions.length" class="suggestions" [ngClass]="{'bottom': isComment}">
    <user-card 
        *ngFor="let user of suggestions; let i = index" 
        [user]="user" [tileOnly]="true" [isLink]="false"
        [ngClass]="{'highlight': i == 0}" 
        (click)="replaceMentionWithNode(user)"
        class="suggestion"
        >
    </user-card>
</div>