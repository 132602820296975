    <div class="modal-overlay" (click)="close()" (paste)="onPaste($event)">
        <div class="modal" stopPropagation>
            <div class="modal-header">
                <div class="modal-title">
                    <img src="/assets/icon-back.svg" alt="back icon" (click)="close()"> 
                    {{ title }}
                </div>
                <div>
                    <button 
                        *ngIf="!showLoader" (click)="submit()"
                        class="btn btn-primary btn-rounded" 
                    > 
                        Post
                    </button>
                </div>
            </div>
            <div class="modal-content">
                <form [formGroup]="postForm" class="modal-form">
                    <user-card [user]="currentUser" [tileOnly]="true"></user-card>
                    <div class="field-group fancy-shmancy">
                        <div class="input-container">
                            <post-editor class="post" (submit)="setPostText($event)" [placeholder]="'Write Something'"></post-editor>
                        </div>
                    </div>
                    <div class="field-group">
                            <div *ngIf="dropZoneAdded" class="image-upload-container" formArrayName="images">
                                <div *ngFor="let image of images.controls; let i = index" [formGroupName]="i" class="image-group">
                                    <file-dropzone #fileDropZoneComponents
                                        [inputName]="'image'" 
                                        [form]="getGroup(i)"
                                        [maxFileSize]="1"
                                        (doFileOutput)="appendImage($event, i)">
                                    </file-dropzone>
                                    <div *ngIf="postImages[i]" class="btn btn-primary" (click)="removeImage(i)" class="remove-image">
                                        <img src="/assets/icon-close.svg" alt="close icon">
                                    </div>   
                                </div>
                            </div>
                        <div class="aligned-center-vertically">
                            <div class="clickable-area" *ngIf="!dropZoneAdded" (click)="showFileDropZone()">
                                <div class="gray-box"></div> 
                                <span class="add-photos">Add Photos</span>
                            </div>
                            <div class="clickable-area" *ngIf="dropZoneAdded" (click)="addMorePhotosButton()">
                                <div class="gray-box"></div> 
                                <span class="add-photos">Add More Photos</span>
                            </div>
                        </div>

                        <div *ngIf="showMessage" class="message">
                            You have reached the maximum of {{images.length}} images.
                        </div>

                    </div>
                </form>
                <p *ngIf="errorText" class="error"></p>
                <button 
                    *ngIf="!showLoader" (click)="submit()"
                    class="btn btn-primary btn-rounded" 
                > 
                    Post
                </button>
                <loading-spinner *ngIf="showLoader"></loading-spinner>
            </div>
        </div>
    </div>