<div class="discussion-feed" *ngIf="!deleted">
    <div class="discussion-post" [ngClass]="{'no-border': noBorder}">
        <div class="discussion-author">
            <user-card [user]="post.posted_by" [tileOnly]="true" [timeStamp]="post.date_created| timeDifference:currentDateTime"></user-card>
            <options-dropdown *ngIf="showOptions" [options]="dropdownOptions" class="dropdown"></options-dropdown>
        </div>     
        <div class="discussion-content" (click)="handleComment()">
            <div #discussionText class="discussion-text" [innerHtml]="processedTxt" [ngClass]="{'truncate-multi-line': !showFullText}" *ngIf="!editPost && !loading"></div>
            <button class="more-text-button" *ngIf="isTruncated" (click)="toggleText($event)">
                {{ showFullText ? 'Show Less' : 'Show More' }}
            </button>
            <div *ngIf="editPost || loading">
                <post-editor *ngIf="!loading" [postToEdit]="post" (submit)="onSubmit($event)" (click)="handleInputClick($event)" ></post-editor> 
                <div class="flex edit-btns" *ngIf="!loading">
                    <button class="btn btn-primary btn-submit" (click)="updatePost($event)">Submit</button>
                    <button class="btn btn-secondary" (click)="cancelEdit($event)">Cancel</button>
                </div>
                <loading-spinner *ngIf="loading"></loading-spinner>
            </div>
            
            <div *ngIf="post.images">
                <image-slider [images]="post.images"></image-slider>
            </div>
        </div>
        
        <div>
            <post-action-bar *ngIf="!hideToolbar"
                (handleComment)="handleComment()"
                [post]="post">
            </post-action-bar>
        </div>

        <div class="comment-input" *ngIf="allowComments && !isComment">
            <div class="initiator" (click)="handleComment()">Comment on this post</div>
        </div>
    </div>
</div>
<alert-popup #alertPopup></alert-popup>
