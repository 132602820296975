import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Post } from 'src/app/_models';
@Component({
  selector: 'comment-action-bar',
  templateUrl: './comment-action-bar.component.html',
  styleUrl: './comment-action-bar.component.scss'
})
export class CommentActionBarComponent {
    @Output() replyAction: EventEmitter<null> = new EventEmitter<null>()
    @Output() likeAction: EventEmitter<null> = new EventEmitter<null>()
    @Input() liked: boolean = false
    @Input() comment!: Post
    @Input() linkReply: boolean = false
    @Input() parentPostId!: string
    @Input() replyParentId: string | null = null

    handleReply(): void{
        this.replyAction.emit()
    }

    handleLike(): void{
        this.likeAction.emit()
    }
}
