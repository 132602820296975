import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, Renderer2, HostListener, } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

import { Subscription } from 'rxjs';
import { PostHelpers } from 'src/app/_helpers';
import {  OptionDropdown, Post } from 'src/app/_models';
import { DiscussionAPIService } from 'src/app/_api-services'
import { AuthenticationService, PostEditorService } from 'src/app/_services';
import { PostEditorComponent } from '../post-editor/post-editor.component';
import { AlertPopupComponent } from 'src/app/shared/common/components/alert-popup/alert-popup.component';

@Component({
  selector: 'comment-card',
  templateUrl: './comment-card.component.html',
  styleUrl: './comment-card.component.scss'
})
export class CommentCardComponent implements OnDestroy, OnInit, AfterViewInit {
    @ViewChild('discussionText', { static: false }) discussionText!: ElementRef<HTMLDivElement>
    @ViewChild(PostEditorComponent) postEditor!: PostEditorComponent
    @ViewChild('alertPopup') alertPopup!: AlertPopupComponent
    
    @Input() type: string = 'comment'
    @Input() roomId!: string
    @Input() comment!: Post
    @Input() replyIsLink: boolean = false
    @Input() showReplies: boolean = true
    @Input() replyParentId: string | null = null
    @Input() deleted: boolean = false
    @Input() parentPost!: Post
    
    @Output() replyAction: EventEmitter<null> = new EventEmitter<null>()
    @Output() delete: EventEmitter<string> = new EventEmitter<string>()
    
    private subscriptions: Subscription = new Subscription()
    
    userId: string
    loading: boolean = false
    replyInputVisible: boolean = false
    liked: boolean = false
    processedTxt!: SafeHtml
    currentDateTime: string = ''
    optionsdropDown: boolean = false
    dropdownOptions: OptionDropdown[] = []
    currentUserId = ""
    commentEdit: boolean = false
    commentTextElement!: HTMLElement
    formData!: FormData
    showOptions: boolean = false
    editComment: boolean = false

    constructor(
        private authService: AuthenticationService,
        private discussionAPIService: DiscussionAPIService,
        private postEditorService: PostEditorService,
        private helper: PostHelpers,
        private cdr: ChangeDetectorRef,
        private renderer: Renderer2,
    ) {
        this.userId = this.authService.currentUserValue.user.id
    }
    
    ngOnInit(): void {
        this.setDropdownOptions()
        this.updateTime()
        
        if(!this.parentPost) {
            throw new Error("parent post must be set")
        }
    }
    
    ngAfterViewInit(): void {
        this.userLiked()
        this.processedTxt = this.helper.processPost(this.comment)
        this.cdr.detectChanges()
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    handleLike(): void {
        if(this.liked) {
            this.subscriptions.add(this.discussionAPIService.unlikePost(this.comment.id).subscribe())
            this.comment.likes = this.comment.likes != undefined ? this.comment.likes - 1 : 0
        } else {
            this.subscriptions.add(this.discussionAPIService.likePost(this.comment.id, this.type, this.parentPost).subscribe())
            this.comment.likes = this.comment.likes != undefined ? this.comment.likes + 1 : 0
        }
        
        this.liked = !this.liked
    }
    
    userLiked(): void {
        if(!this.comment || !this.comment.liked_by) {
            return
        }
        
        let containsUserId = this.comment.liked_by.some((user: any) => this.userId == user)
        this.liked = (this.comment?.liked_by && containsUserId) ? containsUserId : false
    }


    handleReply(): void {
        if(!this.replyIsLink) {
            this.postEditorService.addMention.next(this.comment.posted_by)
        }
        
        this.postEditorService.focus.next()
        this.replyAction.emit()
    }
    
    updateTime(): void {
        this.currentDateTime = new Date().toISOString()
        this.cdr.markForCheck()
        setTimeout(() => {
            this.updateTime()
        }, 60000)
    }

    toggleOptionsDropdown(): void{
        this.optionsdropDown = !this.optionsdropDown
    }
    setDropdownOptions(): void{
        if(this.comment.posted_by.id !== this.userId || this.comment.comments?.length > 0) {
            return
        }
        
        this.showOptions = true
        this.dropdownOptions = [
            { label: 'Delete', action: () => this.showDeleteAlert(), keep_open: false },
            { label: 'Edit', action: () => this.onEditClick(), keep_open: false }
        ]
    }

    showDeleteAlert() {
        this.alertPopup.title = 'Confirm Deletion'
        this.alertPopup.message = 'Are you sure you want to delete this post?'
        this.alertPopup.buttons = [
            {
                name: 'Cancel',
                action: () => this.alertPopup.hide()
            },
            {
                name: 'Delete',
                action: () => {
                    this.deleteComment(this.comment.id)
                    this.alertPopup.hide()
                }
            }
        ]
        this.alertPopup.show()
    }
    onEditClick(): void {
        this.editComment = true
    }
    updateComment(event: Event): void {
        this.loading = true
        event.stopPropagation()
        this.postEditor.post()
        this.editComment = false

    }
    
    cancelEdit(event: Event): void {
        event.stopPropagation()
        this.editComment = false
    }

    onSubmit(text: string): void{
        const room = this.roomId
        const entity = this.replyParentId ? 'comment' : 'post'
        
        if (!parent || text.trim().length < 1) {
            this.loading = false
            return
        }
        
        this.commentEdit = false
        this.formData = new FormData()
        this.formData.append('text', text.trim())
        this.formData.append('entity_id', this.comment.entity.id)
        this.formData.append('rooms', JSON.stringify([room]))
        this.formData.append('entity', entity)
        this.formData.append('links_to', JSON.stringify(this.parentPost))
        this.formData.append('ID',this.comment.id)
        
        this.subscriptions.add(
            this.discussionAPIService.updatePost(this.formData).subscribe(done => {
                this.loading = false
            })
        )
    }
    
    handleInputClick(event: Event){
        event?.stopPropagation()
    }
    
    deleteComment(commentId: string): void {
        this.subscriptions.add(
            this.discussionAPIService.deletePost(commentId).subscribe({
                next: () => {
                    this.deleted = true
                    this.delete.emit(this.comment.id)
                },
                error: (err) => {
                    console.log(err)
                }
                
            })
        )
    }

    updateUserCardPositions(): void {
        const mentions = this.discussionText.nativeElement.querySelectorAll('.mention')
        
        mentions.forEach((span, i) => {
            const anchor = span.querySelector('a')!
            const parentRect = span.getBoundingClientRect()
            const topPos = parentRect.top - 70 //70 is the height of the mention element (can't do dynamically because of display none)
            this.renderer.setStyle(anchor, 'top', `${topPos}px`)
            this.renderer.setStyle(anchor, 'left', `${parentRect.left}px`)
        })
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: Event) {
        this.updateUserCardPositions()
    }
    
    @HostListener('window:resize', ['$event'])
    onWindowsResize(event: Event) {
        this.updateUserCardPositions()
    }
}
