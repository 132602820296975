<div *ngIf="!deleted"> 
    <div class="comment-card">
        <div class="flex-space-between">
            <user-card [user]="comment.posted_by" [timeStamp]="comment.date_created | timeDifference:currentDateTime" [tileOnly]="true"></user-card>
            <options-dropdown [options]="dropdownOptions" *ngIf="showOptions"></options-dropdown>
        </div>
        <div class="main">

            <div class="comment-body">
                <div class="discussion-content">
                    <div #discussionText class="discussion-text" [innerHTML]="processedTxt" *ngIf="!editComment && !loading"></div>
                    <div *ngIf="editComment || loading">
                        <post-editor *ngIf="!loading" [postToEdit]="comment" (submit)="onSubmit($event)" (click)="handleInputClick($event)" ></post-editor> 
                        <div class="flex edit-btns" *ngIf="!loading">
                            <button class="btn btn-primary btn-submit" (click)="updateComment($event)">Submit</button>
                            <button class="btn btn-secondary" (click)="cancelEdit($event)">Cancel</button>
                        </div>
                        <loading-spinner *ngIf="loading"></loading-spinner>
                    </div>
                </div>
            </div>
            <comment-action-bar (replyAction)="handleReply()" (likeAction)="handleLike()" 
                [liked]="liked" [comment]="comment" [linkReply]="replyIsLink" 
                [parentPostId]="roomId" [replyParentId]="replyParentId">
            </comment-action-bar>
        </div>
    </div>
</div>
<div class="replies" *ngIf="showReplies && comment.comments">
    <comment-card *ngFor="let reply of comment.comments" [comment]="reply" [deleted]="deleted"
        [replyIsLink]="replyIsLink" [roomId]="roomId" [replyParentId]="comment.id" [type]="'reply'">
    </comment-card>
</div>
<alert-popup #alertPopup></alert-popup>