import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core'
import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state'
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view'
import { Schema, DOMParser as ProseMirrorDOMParser, Node as ProseMirrorNode, DOMOutputSpec, DOMSerializer} from 'prosemirror-model'
import { schema } from 'prosemirror-schema-basic';
import { keymap } from 'prosemirror-keymap';
import { inputRules, textblockTypeInputRule } from 'prosemirror-inputrules';
import { splitBlock, chainCommands, deleteSelection, joinBackward, selectNodeBackward } from 'prosemirror-commands';
import { TextSelection } from 'prosemirror-state';
import { User,Post } from 'src/app/_models'
import { UserAPIService } from 'src/app/_api-services'
import { PostEditorService } from 'src/app/_services'
import { Subscription } from 'rxjs'
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'post-editor',
  templateUrl: './post-editor.component.html',
  styleUrl: './post-editor.component.scss'
})
export class PostEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('editor', { static: true }) editorElement!: ElementRef<HTMLDivElement>
    @Input() placeholder: string = "Write Comment Here..." 
    @Input() inputFocus: boolean = true
    @Input() postToEdit: Post | null = null
    @Input() isComment: boolean = false
    @Output() submit: EventEmitter<string> = new EventEmitter<string>()
    @Output() onFocus: EventEmitter<void> = new EventEmitter<void>()
    @Output() onBlur: EventEmitter<void> = new EventEmitter<void>()
    
    private subscriptions: Subscription = new Subscription()

    editorView!: EditorView
    suggestions: User[] = []
    mentionPattern = /@\w{1,10}(?:\s\w{1,10})?(?!(\w|\s))/
    mentionText: string = '';
    suggestionHighlightIndex: number = 0
    someHTML: SafeHtml | null = null
    showEditor: boolean = false
    
    constructor(
        private userAPIService: UserAPIService,
        private elem: ElementRef,
        private renderer: Renderer2,
        private postEditorService: PostEditorService,
    ) {
        this.subscriptions.add(
            this.postEditorService.clear.subscribe(() => {
                this.emptyEditor()
            })
        )
    }

    ngOnInit(): void {
        this.initializeEditor()
        this.subscriptions.add(
            this.postEditorService.focus.subscribe( val => {
                this.editorView.focus()
                this.focusing()
            })
        )
        
        this.subscriptions.add(
            this.postEditorService.addMention.subscribe( user => {
                this.emptyAndInsertMention(user)
            })  
        )
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    ngAfterViewInit(): void {
        document.addEventListener('focusout', (event) => {
            if(!this.editorElement || !this.editorElement.nativeElement) {
                return
            }
            
            if(!this.editorElement.nativeElement.contains(event.relatedTarget as Node)) {
                this.blur()
            }
        })
    }
    
    focusing(): void {
        this.onFocus.emit()
    }
    
    blur(): void {
        this.onBlur.emit()
    }
    
    initializeEditor() {
        const mentionNodeSpec = {
            inline: true,
            group: "inline",
            selectable: false,
            atom: true,
            attrs: {
                id: {},
                avatar: {},
                first_name: {},
                last_name: {}
            },
            toDOM: (node: ProseMirrorNode): DOMOutputSpec => {
                return [
                    "span",
                    { class: "mention" },
                    [
                        "a",
                        { class: "user-card" },
                        [
                            "div",
                            { class: "img-container" },
                            ["img", { src: node.attrs['avatar'], alt: `${node.attrs['first_name']} ${node.attrs['last_name']}` }]
                        ],
                        [
                            "div",
                            { class: "flex flex-center user-name" },
                            ["p", `${node.attrs['first_name']} ${node.attrs['last_name']}`]
                        ]
                    ],
                    [
                        "span",
                        { class: "mention-text", "data-user-id": node.attrs['id'] },
                        `@${node.attrs['first_name']} ${node.attrs['last_name']}`
                    ]
                ]
            },
            parseDOM: [{
                tag: "span.mention",
                getAttrs: (dom: HTMLElement) => ({
                    id: dom.querySelector("a.user-card")?.getAttribute("data-id"),
                    avatar: dom.querySelector("img")?.getAttribute("src"),
                    first_name: dom.querySelector(".user-name p")?.textContent?.split(" ")[0],
                    last_name: dom.querySelector(".user-name p")?.textContent?.split(" ")[1]
                })
            }]
        };
    
        // Extend the schema to include the mention node
        const editorSchema = new Schema({
            nodes: schema.spec.nodes.append({
                mention: mentionNodeSpec
            }),
            marks: schema.spec.marks
        });
    
        const plugins = [
            this.createPlacholderPlugin(),
            inputRules({ rules: this.createMentionInputRule(editorSchema.nodes['mention']) }),
            keymap({
                "Enter": (state, dispatch) => {
                    if (this.suggestions.length) {
                        const mentionNode = this.createMentionNode(state, editorSchema.nodes['mention'])
                        if (mentionNode && dispatch) {
                            dispatch(state.tr.replaceSelectionWith(mentionNode))
                            return true
                        }
                        return false
                    }
    
                    if (dispatch) {
                        splitBlock(state, dispatch)
                        return true
                    }
                    return false
                }
            }),
            keymap({
                "Shift-Enter": splitBlock,
                "Ctrl-Enter": splitBlock,
                "Meta-Enter": () => {
                    this.post()
                    return true
                },
            }),
            keymap({
                "Backspace": chainCommands(deleteSelection, joinBackward, selectNodeBackward, (state, dispatch) => {
                    const { $from, empty } = state.selection
                    if (!empty) return false
    
                    const before = $from.nodeBefore
                    if (before && before.isTextblock && before.type.name === 'paragraph') {
                        const tr = state.tr.deleteRange($from.pos - before.nodeSize, $from.pos)
    
                        if (dispatch) {
                            dispatch(tr.scrollIntoView())
                            return true
                        }
                    }
    
                    return false;
                })
            }),
            this.preventImagePaste(),
        ]
    
        const editorState = EditorState.create({
            doc: ProseMirrorDOMParser.fromSchema(editorSchema).parse(this.editorElement.nativeElement),
            schema: editorSchema,
            plugins
        })
    
        this.editorView = new EditorView(this.editorElement.nativeElement, {
            state: editorState,
            dispatchTransaction: this.handleTransaction.bind(this)
        })
        
        if (this.inputFocus) {
            this.editorView.focus()
            this.focusing()
        }
        
        if (this.postToEdit) {
            this.populateEditorWithPost(this.postToEdit.text, this.postToEdit.mentions)
        }
    }
    
    preventImagePaste() {
        return new Plugin({
            props: {
                handlePaste(view: EditorView, event: ClipboardEvent, slice) {
                    const clipboardData = event.clipboardData;
                    if (!clipboardData) {
                        return false
                    }
                    
                    for (let i = 0; i < clipboardData.items.length; i++) {
                        const item = clipboardData.items[i]
                        if (item.kind === 'file' && item.type.startsWith('image/')) {
                            event.preventDefault()
                            return true
                        }
                    }
                    
                    return false
                },
            },
        })
    }

    populateEditorWithPost(text: string | SafeHtml, mentionedUsers: User[]): void {
        let postText = ""
        // Replace instances of <p> with \n and </p> with ''
        if(typeof text != 'string'){
            const tempDiv = document.createElement('div')
            tempDiv.innerHTML = text as string; // Safe cast
            postText = tempDiv.textContent || tempDiv.innerText || ''
        } else {
            postText = text
        }

        postText = postText.replace(/<p>/g, '\n').replace(/<\/p>/g, '')
        postText = postText.trim()
    
    
        const mentionPattern = /@\{([^}]+)\}/g
        const { schema } = this.editorView.state
        const paragraphs: ProseMirrorNode[] = []
    
        // Split text by newline characters
        const lines = postText.split('\n')
    
        lines.forEach(line => {
            const fragment: ProseMirrorNode[] = []
            let lastIndex = 0
            let match
    
            while ((match = mentionPattern.exec(line)) !== null) {
                const [fullMatch, userId] = match
    
                // Add text before the mention
                if (match.index > lastIndex) {
                    const textSlice = line.slice(lastIndex, match.index)
                    const textNode = schema.text(textSlice)
                    fragment.push(textNode)
                }
    
                // Find the user by ID and create a mention node
                const mention = mentionedUsers.find(user => user.id === userId)
                if (mention) {
                    const { id, avatar, first_name, last_name } = mention
                    const mentionNode = schema.nodes['mention'].create({
                        id,
                        avatar,
                        first_name,
                        last_name
                    })
                    fragment.push(mentionNode)
                } else {
                    // Add the full mention text as plain text if user is not found
                    const fallbackTextNode = schema.text(fullMatch)
                    fragment.push(fallbackTextNode)
                }
    
                lastIndex = match.index + fullMatch.length
            }
    
            // Add remaining text after the last mention
            if (lastIndex < line.length) {
                const remainingTextNode = schema.text(line.slice(lastIndex))
                fragment.push(remainingTextNode)
            }
    
            // Create a paragraph node for the current line
            const paragraphNode = schema.nodes['paragraph'].create(null, fragment)
            paragraphs.push(paragraphNode)
        })
    
        // Create a document node and insert the paragraphs
        const doc = schema.nodes['doc'].create(null, paragraphs)
    
        const tr = this.editorView.state.tr.replaceWith(0, this.editorView.state.doc.content.size, doc.content)
    
        this.editorView.dispatch(tr)
        this.updateUserCardPositions()
        this.resetMentions()
    }

    setShowEdit(state: boolean): void {
        this.showEditor = state
    }
    
    createPlacholderPlugin(): Plugin {
        let placeholderText = this.placeholder
        return new Plugin({
            key: new PluginKey('placeholder'),
            props: {
                decorations(state) {
                    const doc = state.doc;
                    const decorations: Decoration[] = []
            
                    if (doc.childCount === 0 || (doc.childCount === 1 && doc.firstChild?.isTextblock && doc.firstChild.content.size === 0)) {

                        const placeholderDecoration = Decoration.node(0, doc.content.size, { class: 'editor-placeholder', 'data-placeholder': placeholderText })
                        decorations.push(placeholderDecoration);
                    }
            
                    return DecorationSet.create(doc, decorations);
                }
            }
        })
    }
    
    handleTransaction(tr: Transaction): void {
        const state = this.editorView.state.apply(tr)
        this.editorView.updateState(state)

        const { empty } = state.selection

        if(!empty) {
            this.resetMentions()
            return
        }
        
        const $from = state.selection.$from
        const textBefore = $from.parent.textBetween(0, $from.parentOffset, " ", " ")

        const mentionMatch = textBefore.match(this.mentionPattern)

        if (mentionMatch) {
            this.mentionText = mentionMatch[0]
            this.fetchSuggestions(this.mentionText)
            return
        }
        
        this.resetMentions()
    }
      
    
    fetchSuggestions(mention: string): void {
        this.subscriptions.add(
            this.userAPIService.getUserSuggestions(mention).subscribe(users => {
                this.suggestions = users ? users : []
            })
        )
      }
    
    createMentionInputRule(nodeType: any) {
        return [textblockTypeInputRule(this.mentionPattern, nodeType, match => {
          const mention = match[0]
          this.fetchSuggestions(mention)
          return {
            firstName: 'Fetching...',
            lastName: '',
            avatar: '',
            id: ''
          }
        })]
      }
    
    createMentionNode(state: EditorState, nodeType: any) {
        const { from, to } = state.selection
        const text = state.doc.textBetween(from, to, " ")
        const match = text.match(this.mentionPattern)
        if (match) {
            const [mention] = match
            this.fetchSuggestions(mention)
            return nodeType.create({
                    firstName: 'Fetching...',
                    lastName: '',
                    avatar: '',
                    id: ''
            })
        }
        return null
    }
    
    replaceMentionWithNode(user: User): void {
        const { id, avatar, first_name, last_name } = user
        const { tr, selection, doc } = this.editorView.state
        const mentionTextLength = this.mentionText.length
        const pos = selection.from - mentionTextLength
        
        if (pos >= 0 && pos + mentionTextLength <= doc.content.size) {
            const node = this.editorView.state.schema.nodes['mention'].create({
                id,
                avatar,
                first_name,
                last_name
            })

            tr.replaceWith(pos, pos + mentionTextLength, node)
            this.editorView.dispatch(tr)
        
            this.updateUserCardPositions()
            this.resetMentions()
            this.editorView.focus()
        }
    }
    
    highlightSuggestion(): void {
        let highlightedSuggestion = this.elem.nativeElement.querySelector('.highlight')
        let suggestions = this.elem.nativeElement.querySelectorAll('.suggestion')
        
        if(highlightedSuggestion) {
            highlightedSuggestion.classList.remove('highlight')
        }
        
        if(!suggestions.length) {
            return
        }
        
        suggestions[this.suggestionHighlightIndex].classList.add('highlight')
        
        this.scrollToItem(suggestions)
    }
    
    scrollToItem(suggestions: any): void {
        suggestions[this.suggestionHighlightIndex].scrollIntoView({ block: 'nearest' })
    }
    
    moveHighlightDown(): void {
        if(!this.suggestions) {
            return
        }

        if(this.suggestionHighlightIndex == this.suggestions.length - 1) {
            this.suggestionHighlightIndex = 0
        } else {
            this.suggestionHighlightIndex++
        }
        
        this.highlightSuggestion()
    }
    
    moveHighlightUp(): void {
        if(!this.suggestions) {
            return
        }
        
        if(this.suggestionHighlightIndex == 0) {
            this.suggestionHighlightIndex = this.suggestions.length - 1
        } else {
            this.suggestionHighlightIndex--
        }
        
        this.highlightSuggestion()
    }
    
    selectHighlightedDropdownItem(): void {
        if(!this.suggestions) {
            return
        }
        
        let suggestion = this.suggestions[this.suggestionHighlightIndex]
        
        this.replaceMentionWithNode(suggestion)
        this.suggestionHighlightIndex = 0
    }
    
    resetMentions(): void {
        this.mentionText = ''
        this.suggestions = []
    }
    
    updateUserCardPositions(): void {
        const mentions = this.editorElement.nativeElement.querySelectorAll('.mention')
        
        mentions.forEach((span, i) => {
            const anchor = span.querySelector('a')!
            const parentRect = span.getBoundingClientRect()
            const topPos = parentRect.top - 70 //70 is the height of the mention element (can't do dynamically because of display none)
            this.renderer.setStyle(anchor, 'top', `${topPos}px`)
            this.renderer.setStyle(anchor, 'left', `${parentRect.left}px`)
        })
    }
    
    getHTMLContent(): HTMLElement {
        const fragment = DOMSerializer.fromSchema(this.editorView.state.schema).serializeFragment(this.editorView.state.doc.content)
        const div = document.createElement('div')
        div.appendChild(fragment)
        
        return div
    }
    
    getFilteredHTMLContent(content: HTMLElement): string {
        const pTags = content.children
        
        for (let i = pTags.length - 1; i >= 0; i--) {
            const pTag = pTags[i]

            if(!pTag.textContent && pTag.childNodes.length === 0) {
                pTag.parentNode?.removeChild(pTag)
                continue
            }
            
            const children = pTag.childNodes
            let replacementPTag = document.createElement('p')

            for(let j = 0; j < children.length; j++) {
                const child = children[j]
                
                if(child.nodeType === Node.ELEMENT_NODE && (child as HTMLElement).classList.contains('mention')) {
                    const childEl = (child as HTMLElement)
                    const mentionTextEl = childEl.querySelector('.mention-text')
                    
                    let mentionId = mentionTextEl?.getAttribute('data-user-id') || ''
                    replacementPTag.appendChild(document.createTextNode(`@{${mentionId}}`))
                    
                    continue
                }
                
                replacementPTag.appendChild(document.createTextNode(child.nodeValue || ''))    
            }
            
            pTag.parentNode?.replaceChild(replacementPTag, pTag)
        }
        
        return content.innerHTML
    }
    
    post(): void {
        const rawHTML = this.getHTMLContent()
        const filteredHTML = this.getFilteredHTMLContent(rawHTML)
        this.submit.emit(filteredHTML)
    }
    
    emptyEditor(): void {
        const { state, dispatch } = this.editorView
        const emptyDoc = state.schema.topNodeType.createAndFill()
        
        if(emptyDoc) {
            const tr = state.tr.replaceWith(0, state.doc.content.size, emptyDoc.content)
            dispatch(tr)
        }
    }
    
    emptyAndInsertMention(user: User): void {
        const { state, dispatch } = this.editorView
        
        const mentionNode = state.schema.nodes['mention'].create({
            id: user.id,
            avatar: user.avatar,
            first_name: user.first_name,
            last_name: user.last_name
        })
        
        let tr = state.tr;
        const emptyDoc = state.schema.nodes['paragraph'].createAndFill()
        tr = tr.replaceWith(0, state.doc.content.size, emptyDoc!.content)

        // Insert the mention node at the beginning
        tr = tr.insert(1, mentionNode)

        // Insert a space after the mention node
        const textNode = state.schema.text(" ")
        tr = tr.insert(mentionNode.nodeSize + 1, textNode)

        // Set the selection to the position after the space
        const resolvedPos = tr.doc.resolve(mentionNode.nodeSize + 2)
        const selection = TextSelection.create(tr.doc, resolvedPos.pos)
        tr = tr.setSelection(selection)

        
        dispatch(tr)
        this.editorView.focus()
    }
    
    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
        const spans = this.editorElement.nativeElement.querySelectorAll('span')
        
        spans.forEach((span, i) => {
            if(!span.contains(event.target as Node)) {
                span.classList.remove('hover')    
            }
        })
    }
    
    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        if (event.key === 'Enter' || event.key == "Tab") {
            if(this.suggestions.length) {
                event.preventDefault()
                this.selectHighlightedDropdownItem()
                return
            }
        }

        if(event.key == "ArrowDown" && this.suggestions.length) {
            this.moveHighlightDown()
            event.preventDefault()
        }
        
        if(event.key == "ArrowUp" && this.suggestions.length) {
            this.moveHighlightUp()
            event.preventDefault()
        }
        
        if(event.key == "Escape") {
            this.resetMentions()
            return
        }
    }
    
    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: Event) {
        this.updateUserCardPositions()
    }
    
    @HostListener('window:resize', ['$event'])
    onWindowsResize(event: Event) {
        this.updateUserCardPositions()
    }
}