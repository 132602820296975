<div class="discussion-interactions">
    <div class="interaction-group action-identities" (click)="handleLike()">
        <img *ngIf="!liked" src="/assets/icon-heart.svg" alt="heart icon">
        <img *ngIf="liked" src="/assets/icon-heart-red.svg" alt="heart icon">
        <span class="interaction-count">{{comment.likes}}</span>
        <div class="all-identities" *ngIf="comment.liked_by && comment.liked_by.length">
            <p *ngFor="let u of comment.liked_by">{{ u.first_name }} {{ u.last_name }}</p>
        </div>
    </div>
    
    <div class="interaction-group">
        <p *ngIf="!linkReply" (click)="handleReply()">Reply</p>
        <a *ngIf="linkReply && !replyParentId" [routerLink]="'/post/' + parentPostId + '/comment/' + comment.id" [queryParams]="{'focus': true}">Reply</a>
        <a *ngIf="linkReply && replyParentId" [routerLink]="'/post/' + parentPostId + '/comment/' + replyParentId" [queryParams]="{'mention': comment.posted_by.id}">Reply</a>
    </div>
</div>
